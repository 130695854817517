import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  is_auth: false,
  token: "",
  profile: "",
};

const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    login: (state, action) => {
      state.is_auth = true;
      state.token = action.payload.token;
      state.profile = action.payload.profile;
      Cookies.set("is_admin_present_token_auth", true);
      Cookies.set("admin_present_token", action.payload.token);
      Cookies.set("admin_present_profile", action.payload.profile);
    },
    
    logout: (state) => {
      state.is_auth = false;
      state.token = "";
      state.profile = "";
      Cookies.remove("is_admin_present_token_auth");
      Cookies.remove("admin_present_token");
      Cookies.remove("admin_present_profile");
    },
  },
});

export default authorizationSlice;

export const { login, logout } = authorizationSlice.actions;
