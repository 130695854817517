import { lazy } from "react";
import Loadable from "../components/Loadable";

const Dashboard = Loadable(lazy(() => import("../view/dashboard")));
const MainLayout = Loadable(lazy(() => import("../layouts/MainLayout")));
const Products = Loadable(lazy(() => import("../view/pages/Products")));
const Categories = Loadable(lazy(() => import("../view/pages/Categories")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <Dashboard />,
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "products",
          element: <Products />,
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "categories",
          element: <Categories />,
        },
      ],
    },
  ],
};

export default MainRoutes;
