import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sizes: [],
  sizeaForSubmiting: [],
};

const sizes = createSlice({
  name: "sizes",
  initialState,
  reducers: {
    add_new_size: (state) => {
      state.sizes.push({
        key: state.sizes.length,
        size: "",
        size_ar: "",
        price: 0,
        calories:0
      });
    },
    delete_size: (state, action) => {
      state.sizes = state.sizes.filter((obj) => obj.key !== action.payload);
    },
    update_size: (state, action) => {
      let oldArray = [...state.sizes];
      oldArray.forEach((element, i) => {
        if (element.key === action.payload.key) {
          const size = {
            key: element.key,
            size: action.payload.size,
            size_ar: action.payload.size_ar,
            price: action.payload.price,
            calories:action.payload.calories
          };
          oldArray[i] = size;
        }
      });
      state.sizes = oldArray;
    },
    ready_to_submiting: (state) => {
      let data = state.sizes.map((size) => {
        return {
          size: size.size,
          price: size.price,
          size_ar: size.size_ar,
          calories:size.calories
        };
      });

      state.sizeaForSubmiting = data;
    },
    reset: (state) => {
      state.sizeaForSubmiting = [];
      state.sizes = [];
    },
  },
});

export default sizes;

export const {
  add_new_size,
  delete_size,
  update_size,
  ready_to_submiting,
  reset,
} = sizes.actions;
